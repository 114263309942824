<template>
  <div>
    <pageTitle :title="pageTitle"></pageTitle>
    <div class="head bb p30 flex fw_w">

    </div>

    <div class="bb pl30 pr30">
      <div class="mb20">
        <el-button type="primary"
                   @click="modifyFormShow = true">新增数据</el-button>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="65vh"
                    style="width: 100%">
            <el-table-column label="ID"
                             width="100"
                             prop="id">
            </el-table-column>
            <el-table-column label="大屏看板ID号"
                             width="120"
                             prop="bs_id">
            </el-table-column>
            <el-table-column label="日期"
                             width="180">
              <template #default="scope">
                {{scope.row.modifDateText}}
              </template>
            </el-table-column>
            <el-table-column label="省份数量"
                             width="250">
              <template #default="scope">
                {{scope.row.provData.length}}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="140"
                             align="center"
                             fixed="right">
              <template #default="scope">
                <el-button type="primary"
                           size="small"
                           @click="handleModify(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="small"
                           @click="handleDel(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog width="680px"
               v-model="modifyFormShow"
               :title="modifyId?'修改数据':'新增数据'"
               :before-close="clearForm">
      <el-form :model="editForm"
               ref="modifyForm">
        <div>
          <el-form-item size="large"
                        label="日期"
                        :label-width="formLabelWidth1">
            <el-date-picker v-model="modifyForm.modifDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="x">
            </el-date-picker>
          </el-form-item>
          <div v-for="(item,index) in modifyForm.provData"
               :key="index">
            <el-form-item size="large"
                          label="省份"
                          :label-width="formLabelWidth1">
              <el-select v-model="item.province"
                         filterable
                         placeholder="请选择省份"
                         style="width: 240px">
                <el-option v-for="items in provinceList"
                           :key="items.name"
                           :label="items.name"
                           :value="items.name" />
              </el-select>
            </el-form-item>
            <el-form-item size="large"
                          label="订单数"
                          :label-width="formLabelWidth1">
              <el-input v-model="item.orderNum"
                        input-style="width: 240px"
                        autocomplete="off" />
            </el-form-item>
            <el-form-item size="large"
                          label="交易额"
                          :label-width="formLabelWidth1">
              <el-input v-model="item.amount"
                        input-style="width: 240px"
                        autocomplete="off" />
            </el-form-item>
            <el-form-item @click="delItem(index)"
                          v-if="modifyForm.provData.length > 1"
                          :label-width="formLabelWidth1">
              <el-button type="danger">删除</el-button>
            </el-form-item>
          </div>
          <el-form-item :label-width="formLabelWidth1">
            <el-button @click="addItem"
                       type="primary">增加一项</el-button>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm">取消</el-button>
          <el-button type="primary"
                     @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/common";
import pageTitle from "@/common/pageTitle.vue";
import { formatTime } from "@/utils/common";
import provinceList from "@/utils/province";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      pageTitle: "城市管理列表",
      type: 1,
      title: "", // 关键词
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      modifyFormShow: false,
      modifyId: null,
      modifyForm: {
        provData: [
          {
            province: "",
            orderNum: "",
            amount: "",
          },
        ],
        modifDate: "",
      },
      formLabelWidth1: "150px",
      provinceList: [],
    };
  },
  mounted() {
    this.provinceList = provinceList;
    this.getProvMapList(this.page);
  },
  methods: {
    retrieval() {
      this.page = 1;
      this.tableData = [];
      this.getProvMapList(this.page);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getProvMapList(val);
    },
    // 筛选新闻列表
    async getProvMapList(page) {
      this.loading = true;
      const res = await Service.getProvMapList({
        bs_id: localStorage.getItem("bs_id"),
        type: this.type,
        page,
        pageSize: this.pageSize,
      });
      var tableData = res.data;
      for (const item of tableData) {
        item.modifDateText = formatTime(item.modifDate / 1000, "Y-M-D");
      }
      this.tableData = tableData;
      this.total = res.count;
      this.loading = false;
    },
    handleModify(index, row) {
      this.modifyId = row.id;
      this.modifyForm.provData = row.provData;
      this.modifyForm.modifDate = row.modifDate;
      this.modifyFormShow = true;
    },
    handleDel(index, row) {
      this.$confirm("确认删除该条记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelApi(row.id);
        })
        .catch(() => {});
    },
    async handleDelApi(id) {
      const res = await Service.delProvMap({
        access_token: localStorage.getItem("access_token"),
        id,
      });
      this.$message.success(res.resultMsg);
      this.getProvMapList(this.page);
    },
    clearForm() {
      this.modifyId = null;
      this.gmv = "";
      this.orderNum = "";
      this.modifyForm.provData = [
        {
          province: "",
          orderNum: "",
          amount: "",
        },
      ];
      this.modifyForm.modifDate = "";
      this.modifyFormShow = false;
    },
    delItem(index) {
      this.modifyForm.provData.splice(index, 1);
    },
    addItem() {
      this.modifyForm.provData.push({
        province: "",
        orderNum: "",
        amount: "",
      });
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      var modifyId = this.modifyId;
      var num = 0;
      for (const item of modifyForm.provData) {
        if (!item.province) {
          num++;
        }
        if (!item.orderNum) {
          num++;
        }
        if (!item.amount) {
          num++;
        }
      }
      if (!modifyForm.modifDate) {
        this.$message.error("日期不可为空");
        return false;
      }
      if (num) {
        this.$message.error("数据不可为空");
        return false;
      }

      let res = null;
      if (!modifyId) {
        res = await Service.addProvMap({
          access_token: localStorage.getItem("access_token"),
          bs_id: localStorage.getItem("bs_id"),
          provData: modifyForm.provData,
          modifDate: modifyForm.modifDate,
        });
      } else {
        res = await Service.editProvMap({
          access_token: localStorage.getItem("access_token"),
          id: modifyId,
          provData: modifyForm.provData,
          modifDate: modifyForm.modifDate,
        });
      }

      this.$message.success(res.resultMsg);
      this.modifyFormShow = false;
      this.clearForm();
      this.getProvMapList(this.page);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>