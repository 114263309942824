<template>
  <div class="sidebar">
    <el-menu :default-active="onRoutes"
             class="el-menu-vertical-demo"
             :collapse="collapse"
             background-color="#26292C"
             active-text-color="#fff"
             text-color="#B8C7CE"
             router>
      <div class="cell">
        <img class="head_img"
             src="@/assets/img/logo.png"
             alt="">
      </div>
      <div v-for="(item,index) in items"
           :key="index">
        <el-menu-item :index="item.index"
                      v-if="item.isShow">
          <div class="pr">
            <div class="red_point"
                 v-if="item.index=='phoneNumberList' && tipsNum"></div>
            <img class="menu_icon"
                 :src="onRoutes==item.index?item.icon_active:item.icon"
                 alt="">
            {{item.name}}
          </div>
          <!-- <template #title>{{item.name}}</template> -->
        </el-menu-item>
      </div>
      
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapse: true,
      items: [
        {
          index: "managerList",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "管理员列表",
          isShow: localStorage.getItem("access_name") == "admin",
        },
        {
          index: "bsList",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "看板列表",
          isShow: localStorage.getItem("access_name") == "admin",
        },
        {
          index: "exponent",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "指数贡献",
          isShow: true,
        },
        {
          index: "dataOverview",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "数据概览",
          isShow: true,
        },
        {
          index: "marketOverview",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "行情概览",
          isShow: true,
        },
        {
          index: "rankingList",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "TOP10排行榜",
          isShow: true,
        },
        {
          index: "categories",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "分类管理",
          isShow: true,
        },
        {
          index: "article",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "文章管理",
          isShow: true,
        },
        {
          index: "financingAmount",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "今日融资额",
          isShow: true,
        },
        {
          index: "carbonImpairment",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "碳减值贡献",
          isShow: true,
        },
        {
          index: "technology",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "科技成果交易",
          isShow: true,
        },
        {
          index: "cityManagement",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "城市管理",
          isShow: true,
        },
        {
          index: "advertisingSpace",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "广告位管理",
          isShow: true,
        },
        {
          index: "timeSetting",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "时间设置",
          isShow: true,
        },
      ],
      tipsNum: 0,
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {},
  mounted() {},
  methods: {
    
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  overflow-y: scroll;
}

.cell {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head_img {
  height: 30px;
}

.menu_icon {
  width: 32px;
  height: 32px;
  position: relative;
  left: -5.5px;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar > ul {
  height: 100%;
  overflow-y: auto;
}

.el-menu--collapse {
  width: 100%;
}

.el-menu-item * {
  vertical-align: middle !important;
}

.el-menu-item.is-active {
  background-color: #5188f4;
}

.red_point {
  position: absolute;
  top: 10px;
  right: -5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: red;
}
</style>
