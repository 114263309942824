<template>
  <div class="wrapper">
    <v-sidebar></v-sidebar>
    <div class="content-header">
      <div class="flex at_c">
        <div class="fz14 mr20"
             style="color: #fff;">当前登录账号：{{username}}</div>
        <div>
          <el-select @change="changeBsId"
                     v-model="bs_id"
                     filterable
                     placeholder="请选择看板"
                     style="width: 240px">
            <el-option v-for="item in bsList"
                       :key="item.bsId"
                       :label="item.name"
                       :value="item.bsId" />
          </el-select>
        </div>
      </div>

      <div class="sign_out"
           @click="signOut">退出登录</div>
    </div>
    <div class="cori-content-box overflow_y">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import vSidebar from "./Sidebar.vue";
export default {
  data() {
    return {
      bsList: [],
      username: "",
      bs_id: null,
    };
  },
  components: {
    vSidebar,
  },
  created() {
    this.bsList = JSON.parse(localStorage.getItem("access_bsList"));
    this.bs_id = localStorage.getItem("bs_id") * 1 || this.bsList[0].bsId;
    localStorage.setItem("bs_id", this.bs_id);
    this.username = localStorage.getItem("access_name");
  },
  mounted() {
    
  },
  methods: {
    changeBsId(e) {
      console.log("e", e);
      this.bs_id = e;
      localStorage.setItem("bs_id", e);
      window.location.reload();
    },
    signOut() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("access_name");
      localStorage.removeItem("access_bsList");
      localStorage.removeItem("bs_id");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.cori-content-box {
  position: fixed;
  left: 200px;
  top: 40px;
  bottom: 0;
  right: 0;
}

.content-header {
  position: fixed;
  width: calc(100% - 200px);
  left: 200px;
  top: 0;
  height: 40px;
  background-color: #26292c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
}

.sign_out {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  background-color: #d42c2c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
</style>
