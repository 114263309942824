import request from "@/utils/request";

// 管理员登录后台系统
export const login = (query) => {
  return request({
    url: "/bigscreen/manager/login",
    method: "GET",
    params: query,
  });
};
// 查询管理员列表
export const getManagerList = (query) => {
  return request({
    url: "/bigscreen/manager/list",
    method: "GET",
    params: query,
  });
};
// 添加新管理员账号
export const addManager = (query) => {
  return request({
    url: "/bigscreen/manager/add",
    method: "GET",
    params: query,
  });
};
// 修改其他管理员账号的密码和看板
export const editManager = (query) => {
  return request({
    url: "/bigscreen/manager/edit",
    method: "GET",
    params: query,
  });
};
// 删除其他管理员账号
export const delManager = (query) => {
  return request({
    url: "/bigscreen/manager/del",
    method: "GET",
    params: query,
  });
};
// 查询全部看板列表
export const getBSList = (query) => {
  return request({
    url: "/bigscreen/bs/list",
    method: "GET",
    params: query,
  });
};
// 新建看板
export const addBS = (query) => {
  return request({
    url: "/bigscreen/bs/add",
    method: "GET",
    params: query,
  });
};
// 修改看板名称（中心标题）
export const editBS = (query) => {
  return request({
    url: "/bigscreen/bs/edit",
    method: "GET",
    params: query,
  });
};
// 删除看板+各模块数据
export const delBS = (query) => {
  return request({
    url: "/bigscreen/bs/del",
    method: "GET",
    params: query,
  });
};
// 查询模块的标题名
export const getMtitle = (query) => {
  return request({
    url: "/bigscreen/bs/get",
    method: "GET",
    params: query,
  });
};
// 修改模块的标题名
export const editMtitle = (query) => {
  return request({
    url: "/bigscreen/bs/editMtitle",
    method: "GET",
    params: query,
  });
};
// 获取大屏看板后台设置的[截止日期时间戳&折线图展示天数]
export const getBSendTime = (query) => {
  return request({
    url: "/bigscreen/bs/getBSendTime",
    method: "GET",
    params: query,
  });
};
// 设置大屏端（截止展示时间&折现图展示天数）
export const setBSendTime = (query) => {
  return request({
    url: "/bigscreen/bs/setBSendTime",
    method: "GET",
    params: query,
  });
};
// 查询全国数据列表(各省地图销量)
export const getProvMapList = (query) => {
  return request({
    url: "/bigscreen/provMap/list",
    method: "GET",
    params: query,
  });
};
// 添加一条全国数据(各省地图销量)
export const addProvMap = (query) => {
  return request({
    url: "/bigscreen/provMap/add?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 修改某一条全国数据(各省地图销量)
export const editProvMap = (query) => {
  return request({
    url: "/bigscreen/provMap/edit?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 删除一条全国数据(各省地图销量)
export const delProvMap = (query) => {
  return request({
    url: "/bigscreen/provMap/del",
    method: "GET",
    params: query,
  });
};
// 筛选今日行情概览信息
export const getDayPriceList = (query) => {
  return request({
    url: "/bigscreen/dayPrice/list",
    method: "GET",
    params: query,
  });
};
// 新增一条今日行情概览信息
export const addDayPrice = (query) => {
  return request({
    url: "/bigscreen/dayPrice/add?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 修改某条行情概览信息
export const editDayPrice = (query) => {
  return request({
    url: "/bigscreen/dayPrice/edit?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 删除某条行情概览信息
export const delDayPrice = (query) => {
  return request({
    url: "/bigscreen/dayPrice/del",
    method: "GET",
    params: query,
  });
};
// 筛选数据概览信息
export const getDataViewList = (query) => {
  return request({
    url: "/bigscreen/dataView/list",
    method: "GET",
    params: query,
  });
};
// 新增一条数据概览信息
export const addDataView = (query) => {
  return request({
    url: "/bigscreen/dataView/add?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 修改某条数据概览信息
export const editDataView = (query) => {
  return request({
    url: "/bigscreen/dataView/edit?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 删除某条数据概览信息
export const delDataView = (query) => {
  return request({
    url: "/bigscreen/dataView/del",
    method: "GET",
    params: query,
  });
};
// 筛选指定类型数据组
export const getCustmDataList = (query) => {
  return request({
    url: "/bigscreen/custmData/list",
    method: "GET",
    params: query,
  });
};
// 添加指定类型数据组
export const addCustmData = (query) => {
  return request({
    url: "/bigscreen/custmData/add?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 修改某条数据组和日期
export const editCustmData = (query) => {
  return request({
    url: "/bigscreen/custmData/edit?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 删除某条数据组
export const delCustmData = (query) => {
  return request({
    url: "/bigscreen/custmData/del",
    method: "GET",
    params: query,
  });
};
// 获取榜单列表
export const getRankList = (query) => {
  return request({
    url: "/bigscreen/rank/list",
    method: "GET",
    params: query,
  });
};
// 添加一组榜单数据
export const addRank = (query) => {
  return request({
    url: "/bigscreen/rank/addList?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 修改某一组榜单数据
export const editRank = (query) => {
  return request({
    url: "/bigscreen/rank/edit?access_token=" + localStorage.getItem("access_token"),
    method: "POST",
    data: query,
  });
};
// 删除某一组榜单数据
export const delRank = (query) => {
  return request({
    url: "/bigscreen/rank/del",
    method: "GET",
    params: query,
  });
};
// 筛选某看版下的广告位文件列表
export const getAdvertList = (query) => {
  return request({
    url: "/bigscreen/advert/list",
    method: "GET",
    params: query,
  });
};
// 新增广告位文件
export const addAdvert = (query) => {
  return request({
    url: "/bigscreen/advert/add",
    method: "GET",
    params: query,
  });
};
// 修改某个广告位文件
export const editAdvert = (query) => {
  return request({
    url: "/bigscreen/advert/edit",
    method: "GET",
    params: query,
  });
};
// 删除某个广告位文件
export const delAdvert = (query) => {
  return request({
    url: "/bigscreen/advert/del",
    method: "GET",
    params: query,
  });
};
// 获取所有头条类型
export const getAllType = (query) => {
  return request({
    url: "/bigscreen/newsTou/allType",
    method: "GET",
    params: query,
  });
};
// 新增一个头条类型
export const addType = (query) => {
  return request({
    url: "/bigscreen/newsTou/addType",
    method: "GET",
    params: query,
  });
};
// ID修改某个头条类型名称
export const editType = (query) => {
  return request({
    url: "/bigscreen/newsTou/editType",
    method: "GET",
    params: query,
  });
};
// ID删除某个头条类型
export const delType = (query) => {
  return request({
    url: "/bigscreen/newsTou/delType",
    method: "GET",
    params: query,
  });
};
// 筛选新闻列表
export const getNewsTouList = (query) => {
  return request({
    url: "/bigscreen/newsTou/list",
    method: "GET",
    params: query,
  });
};
// 新增一条新闻信息
export const addNewsTou = (query) => {
  return request({
    url: "/bigscreen/newsTou/add",
    method: "GET",
    params: query,
  });
};
// 修改某条新闻信息
export const editNewsTou = (query) => {
  return request({
    url: "/bigscreen/newsTou/edit",
    method: "GET",
    params: query,
  });
};
// 删除某条新闻信息
export const delNewsTou = (query) => {
  return request({
    url: "/bigscreen/newsTou/del",
    method: "GET",
    params: query,
  });
};