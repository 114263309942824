const provinceList = [
  {
    name: '北京',
  },
  {
    name: '天津',
  },
  {
    name: '上海',
  },
  {
    name: '重庆',
  },
  {
    name: '河北',
  },
  {
    name: '河南',
  },
  {
    name: '山东',
  },
  {
    name: '山西',
  },
  {
    name: '陕西',
  },
  {
    name: '内蒙古',
  },
  {
    name: '辽宁',
  },
  {
    name: '吉林',
  },
  {
    name: '黑龙江',
  },
  {
    name: '江苏',
  },
  {
    name: '浙江',
  },
  {
    name: '安徽',
  },
  {
    name: '湖北',
  },
  {
    name: '湖南',
  },
  {
    name: '江西',
  },
  {
    name: '福建',
  },
  {
    name: '广东',
  },
  {
    name: '海南',
  },
  {
    name: '广西',
  },
  {
    name: '贵州',
  },
  {
    name: '云南',
  },
  {
    name: '四川',
  },
  {
    name: '甘肃',
  },
  {
    name: '宁夏',
  },
  {
    name: '新疆',
  },
  {
    name: '青海',
  },
  {
    name: '西藏',
  },
  {
    name: '香港',
  },
  {
    name: '澳门',
  },
  {
    name: '台湾',
  },
]

export default provinceList