<template>
  <div>
    <pageTitle :title="pageTitle"></pageTitle>
    <div class="head bb p30 flex fw_w">
      <div>
        <div>广告位标题</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="title"
                    placeholder="请输入广告位标题"></el-input>
        </div>
      </div>
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div>
    </div>

    <div class="bb pl30 pr30">
      <div class="mb20">
        <el-button type="primary"
                   @click="modifyFormShow = true">新增广告位</el-button>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="65vh"
                    style="width: 100%">
            <el-table-column label="ID"
                             width="100"
                             prop="id">
            </el-table-column>
            <el-table-column label="大屏看板ID号"
                             width="120"
                             prop="bs_id">
            </el-table-column>
            <el-table-column label="广告位标题"
                             width="250"
                             prop="title">
            </el-table-column>
            <el-table-column label="广告文件"
                             width="250"
                             prop="newsLink">
              <template #default="scope">
                <el-link type="primary"
                         @click="copyText(scope.row.adUrl)">{{scope.row.adUrl}}</el-link>
              </template>

            </el-table-column>
            <el-table-column label="跳转外链接"
                             width="250"
                             prop="newsLink">
              <template #default="scope">
                <el-link type="primary"
                         @click="copyText(scope.row.linkAdress)">{{scope.row.linkAdress}}</el-link>
              </template>

            </el-table-column>
            <el-table-column label="显示状态"
                             width="100">
              <template #default="scope">
                {{scope.row.status==1?'显示':'隐藏'}}
              </template>
            </el-table-column>
            <el-table-column label="修改时间"
                             width="180">
              <template #default="scope">
                {{scope.row.modifTimeText}}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="140"
                             fixed="right"
                             align="center">
              <template #default="scope">
                <el-button type="primary"
                           size="small"
                           @click="handleModify(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="small"
                           @click="handleDel(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog width="680px"
               v-model="modifyFormShow"
               :title="modifyId?'修改广告位':'新增广告位'"
               :destroy-on-close="true"
               :before-close="clearForm">
      <el-form :model="editForm"
               ref="modifyForm">
        <div>
          <el-form-item size="large"
                        label="广告位标题"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.title"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="广告文件"
                        :label-width="formLabelWidth1">
            <el-upload class="upload-demo"
                       action="https://meat.chinausmeat.com/meat/ln/common/upload/uploadFile"
                       :on-preview="handlePreview"
                       :on-remove="handleRemove"
                       :on-success="handleSuccess"
                       :before-remove="beforeRemove"
                       multiple
                       :limit="1"
                       :file-list="fileList">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item size="large"
                        label="跳转外链接"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.linkAdress"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="序号"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.sortNo"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item v-if="modifyId"
                        size="large"
                        label="显示状态"
                        :label-width="formLabelWidth1">
            <el-radio v-model="modifyForm.status"
                      :label="1">显示</el-radio>
            <el-radio v-model="modifyForm.status"
                      :label="0">隐藏</el-radio>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm">取消</el-button>
          <el-button type="primary"
                     @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/common";
import pageTitle from "@/common/pageTitle.vue";
import { formatTime } from "@/utils/common";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      pageTitle: "广告位列表",
      title: "", // 关键词
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      modifyFormShow: false,
      modifyId: null,
      modifyForm: {
        title: "",
        adUrl: "",
        linkAdress: "",
        sortNo: "",
        status: 1,
      },
      formLabelWidth1: "150px",
      fileList: [],
    };
  },
  mounted() {
    this.getAdvertList(this.page);
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSuccess(res, file) {
      console.log("@", this.fileList);
      this.modifyForm.adUrl = "https://meat.chinausmeat.com" + res.data;
    },
    retrieval() {
      this.page = 1;
      this.tableData = [];
      this.getAdvertList(this.page);
    },
    handleCurrentChange(val) {
      this.page = val
      this.getAdvertList(val);
    },
    // 筛选广告位列表
    async getAdvertList(page) {
      this.loading = true;
      const res = await Service.getAdvertList({
        bs_id: localStorage.getItem("bs_id"),
        title: this.title,
        page,
        pageSize: this.pageSize
      });
      var tableData = res.data;
      for (const item of tableData) {
        item.modifTimeText = formatTime(item.modifTime / 1000, "Y-M-D h:m:s");
      }
      this.tableData = tableData;
      this.total = res.count;
      this.loading = false;
    },
    async copyText(e) {
      await navigator.clipboard.writeText(e);
      this.$message.success("已复制到剪贴板");
    },
    handleModify(index, row) {
      this.modifyId = row.id;
      this.modifyForm.title = row.title;
      this.modifyForm.adUrl = row.adUrl;
      this.modifyForm.linkAdress = row.linkAdress;
      this.modifyForm.sortNo = row.sortNo;
      this.modifyForm.status = row.status;
      this.modifyFormShow = true;
    },
    handleDel(index, row) {
      this.$confirm("确认删除该条记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelApi(row.id);
        })
        .catch(() => {});
    },
    async handleDelApi(id) {
      const res = await Service.delAdvert({
        access_token: localStorage.getItem("access_token"),
        id,
      });
      this.$message.success(res.resultMsg);
      this.getAdvertList(this.page);
    },
    clearForm() {
      this.modifyId = null;
      this.modifyForm.title = "";
      this.modifyForm.adUrl = "";
      this.modifyForm.linkAdress = "";
      this.modifyForm.sortNo = "";
      this.modifyForm.status = "";
      this.modifyFormShow = false;
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      var modifyId = this.modifyId;
      // if (!modifyForm.title) {
      //   this.$message.error("广告位标题不可为空");
      //   return false;
      // }
      if (!modifyForm.adUrl) {
        this.$message.error("广告文件不可为空");
        return false;
      }
      // if (!modifyForm.linkAdress) {
      //   this.$message.error("跳转外链接不可为空");
      //   return false;
      // }
      // if (!modifyForm.sortNo) {
      //   this.$message.error("序号不可为空");
      //   return false;
      // }
      let res = null;
      if (!modifyId) {
        res = await Service.addAdvert({
          access_token: localStorage.getItem("access_token"),
          bs_id: localStorage.getItem("bs_id"),
          sortNo: modifyForm.sortNo,
          title: modifyForm.title,
          adUrl: modifyForm.adUrl,
          linkAdress: modifyForm.linkAdress,
          modifTime: Math.round(new Date()),
        });
      } else {
        res = await Service.editAdvert({
          access_token: localStorage.getItem("access_token"),
          id: modifyId,
          sortNo: modifyForm.sortNo,
          title: modifyForm.title,
          adUrl: modifyForm.adUrl,
          linkAdress: modifyForm.linkAdress,
          modifTime: Math.round(new Date()),
          status: modifyForm.status,
        });
      }

      this.$message.success(res.resultMsg);
      this.modifyFormShow = false;
      this.clearForm();
      this.getAdvertList(this.page);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>