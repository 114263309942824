<template>
  <div>
    <pageTitle :title="pageTitle"></pageTitle>
    <div class="head bb p30 flex fw_w">

    </div>

    <div class="bb pl30 pr30">
      <div class="mb20">
        <el-button type="primary"
                   @click="modifyFormShow = true">新增数据</el-button>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="65vh"
                    style="width: 100%">
            <el-table-column label="ID"
                             width="100"
                             prop="id">
            </el-table-column>
            <el-table-column label="大屏看板ID号"
                             width="120"
                             prop="bs_id">
            </el-table-column>
            <el-table-column label="日期"
                             width="180">
              <template #default="scope">
                {{scope.row.modifDateText}}
              </template>
            </el-table-column>
            <el-table-column label="猪价"
                             width="120"
                             prop="pigPrice">
            </el-table-column>
            <el-table-column label="肉价"
                             width="120"
                             prop="porkPrice">
            </el-table-column>
            <el-table-column label="原料"
                             width="120"
                             prop="fodderPrice">
            </el-table-column>
            <el-table-column label="数据"
                             width="250">
              <template #default="scope">
                <div v-for="(item,index) in scope.row.data"
                     :key="index">
                  {{index}}:{{item}}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="140"
                             align="center"
                             fixed="right">
              <template #default="scope">
                <el-button type="primary"
                           size="small"
                           @click="handleModify(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="small"
                           @click="handleDel(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog width="680px"
               v-model="modifyFormShow"
               :title="modifyId?'修改数据':'新增数据'"
               :before-close="clearForm">
      <el-form :model="editForm"
               ref="modifyForm">
        <div>
          <el-form-item size="large"
                        label="日期"
                        :label-width="formLabelWidth1">
            <el-date-picker v-model="modifyForm.modifDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="x">
            </el-date-picker>
          </el-form-item>
          <el-form-item size="large"
                        label="猪价"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.pigPrice"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="肉价"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.porkPrice"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="原料"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.fodderPrice"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题1"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[0].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值1"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[0].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题2"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[1].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值2"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[1].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题3"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[2].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值3"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[2].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题4"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[3].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值4"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[3].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题5"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[4].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值5"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[4].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题6"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[5].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值6"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[5].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题7"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[6].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值7"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[6].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题8"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[7].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值8"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[7].val"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据标题9"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[8].name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="数据数值9"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.data[8].val"
                      autocomplete="off" />
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm">取消</el-button>
          <el-button type="primary"
                     @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/common";
import pageTitle from "@/common/pageTitle.vue";
import { formatTime } from "@/utils/common";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      pageTitle: "行情概览列表",
      type: 1,
      title: "", // 关键词
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      modifyFormShow: false,
      modifyId: null,
      modifyForm: {
        pigPrice: "",
        porkPrice: "",
        fodderPrice: "",
        data: [
          {
            name: "今日白猪",
            val: "1",
          },
          {
            name: "今日土猪",
            val: "2",
          },
          {
            name: "今日豆粕",
            val: "3",
          },
          {
            name: "今日白猪肉",
            val: "4",
          },
          {
            name: "今日土猪肉",
            val: "5",
          },
          {
            name: "资源土猪肉",
            val: "6",
          },
          {
            name: "今日牛肉",
            val: "7",
          },
          {
            name: "今日羊肉",
            val: "8",
          },
          {
            name: "今日鸡肉",
            val: "9",
          },
        ],
        modifDate: "",
      },
      formLabelWidth1: "150px",
    };
  },
  mounted() {
    this.getDayPriceList(this.page);
  },
  methods: {
    retrieval() {
      this.page = 1;
      this.tableData = [];
      this.getDayPriceList(this.page);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDayPriceList(val);
    },
    // 筛选新闻列表
    async getDayPriceList(page) {
      this.loading = true;
      const res = await Service.getDayPriceList({
        bs_id: localStorage.getItem("bs_id"),
        type: this.type,
        page,
        pageSize: this.pageSize,
      });
      var tableData = res.data;
      for (const item of tableData) {
        item.modifDateText = formatTime(item.modifDate / 1000, "Y-M-D");
      }
      this.tableData = tableData;
      this.total = res.count;
      this.loading = false;
    },
    handleModify(index, row) {
      this.modifyId = row.id;
      var obj = row.data;
      var data = [];
      for (const i in obj) {
        data.push({
          name: i,
          val: obj[i],
        });
      }
      this.modifyForm.pigPrice = row.pigPrice;
      this.modifyForm.porkPrice = row.porkPrice;
      this.modifyForm.fodderPrice = row.fodderPrice;
      this.modifyForm.data = data;
      this.modifyForm.modifDate = row.modifDate;
      this.modifyFormShow = true;
    },
    handleDel(index, row) {
      this.$confirm("确认删除该条记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelApi(row.id);
        })
        .catch(() => {});
    },
    async handleDelApi(id) {
      const res = await Service.delDayPrice({
        access_token: localStorage.getItem("access_token"),
        id,
      });
      this.$message.success(res.resultMsg);
      this.getDayPriceList(this.page);
    },
    clearForm() {
      this.modifyId = null;
      this.modifyForm.pigPrice = "";
      this.modifyForm.porkPrice = "";
      this.modifyForm.fodderPrice = "";
      this.modifyForm.data = [
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
        {
          name: "",
          val: "",
        },
      ];
      this.modifyForm.modifDate = "";
      this.modifyFormShow = false;
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      var modifyId = this.modifyId;
      var num = 0;
      var data = {};
      for (const item of modifyForm.data) {
        if (!item.name) {
          num++;
        }
        if (!item.val) {
          num++;
        }
        data[item.name] = item.val;
      }
      if (!modifyForm.modifDate) {
        this.$message.error("日期不可为空");
        return false;
      }
      if (!modifyForm.pigPrice) {
        this.$message.error("猪价不可为空");
        return false;
      }
      if (!modifyForm.porkPrice) {
        this.$message.error("肉价不可为空");
        return false;
      }
      if (!modifyForm.fodderPrice) {
        this.$message.error("原料不可为空");
        return false;
      }
      if (num) {
        this.$message.error("数据不可为空");
        return false;
      }

      let res = null;
      if (!modifyId) {
        res = await Service.addDayPrice({
          access_token: localStorage.getItem("access_token"),
          bs_id: localStorage.getItem("bs_id"),
          pigPrice: modifyForm.pigPrice,
          porkPrice: modifyForm.porkPrice,
          fodderPrice: modifyForm.fodderPrice,
          data,
          modifDate: modifyForm.modifDate,
        });
      } else {
        res = await Service.editDayPrice({
          access_token: localStorage.getItem("access_token"),
          id: modifyId,
          pigPrice: modifyForm.pigPrice,
          porkPrice: modifyForm.porkPrice,
          fodderPrice: modifyForm.fodderPrice,
          data,
          modifDate: modifyForm.modifDate,
        });
      }

      this.$message.success(res.resultMsg);
      this.modifyFormShow = false;
      this.clearForm();
      this.getDayPriceList(this.page);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>