import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import jquery from "jquery";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "./assets/css/BaoUi.css";
import "./assets/css/main.css";
import VueClipboard from 'vue-clipboard2'
import store from './store/index'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  const role1 = localStorage.getItem("access_token");
  const role2 = localStorage.getItem("access_name");
  const role3 = localStorage.getItem("access_bsList");
  const roleName = localStorage.getItem("access_name");
  const authorityUrls = ['/managerList', '/bsList']
  if (!role1 && !role2 && !role3 && to.path !== "/login") {
    next("/login");
  } else {
    if (roleName != 'admin' && authorityUrls.includes(to.path)) {
      next('/404');
    }
    next();
  }
});

app.use(router)
  .use(jquery)
  .use(store)
  .use(ElementPlus, { locale })
  .use(VueClipboard)
  .mount("#app");
