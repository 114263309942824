import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/common/Home";
import NotFound from "@/views/404";
import Login from "@/views/Login";
import Exponent from "@/views/Exponent";
import ManagerList from "@/views/ManagerList";
import BsList from "@/views/BsList";
import DataOverview from "@/views/DataOverview";
import MarketOverview from "@/views/MarketOverview";
import RankingList from "@/views/RankingList";
import News from "@/views/News";
import Categories from "@/views/Categories";
import Article from "@/views/Article";
import FinancingAmount from "@/views/FinancingAmount";
import CarbonImpairment from "@/views/CarbonImpairment";
import Technology from "@/views/Technology";
import CityManagement from "@/views/CityManagement";
import AdvertisingSpace from "@/views/AdvertisingSpace";
import TimeSetting from "@/views/TimeSetting";

const routes = [
  {
    path: "/",
    redirect: "/exponent",
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: '/managerList',
        component: ManagerList,
        meta: { title: '管理员列表' }
      },
      {
        path: '/bsList',
        component: BsList,
        meta: { title: '看板列表' }
      },
      {
        path: '/exponent',
        component: Exponent,
        meta: { title: '指数贡献' }
      },
      {
        path: '/dataOverview',
        component: DataOverview,
        meta: { title: '数据概览' }
      },
      {
        path: '/marketOverview',
        component: MarketOverview,
        meta: { title: '行情概览' }
      },
      {
        path: '/rankingList',
        component: RankingList,
        meta: { title: 'TOP10排行榜' }
      },
      {
        path: '/news',
        component: News,
        meta: { title: '新闻动态' }
      },
      {
        path: '/categories',
        component: Categories,
        meta: { title: '分类管理' }
      },
      {
        path: '/article',
        component: Article,
        meta: { title: '文章管理' }
      },
      {
        path: '/financingAmount',
        component: FinancingAmount,
        meta: { title: '今日融资额' }
      },
      {
        path: '/carbonImpairment',
        component: CarbonImpairment,
        meta: { title: '碳减值贡献' }
      },
      {
        path: '/technology',
        component: Technology,
        meta: { title: '科技成果交易' }
      },
      {
        path: '/cityManagement',
        component: CityManagement,
        meta: { title: '城市管理' }
      },
      {
        path: '/advertisingSpace',
        component: AdvertisingSpace,
        meta: { title: '广告位管理' }
      },
      {
        path: '/timeSetting',
        component: TimeSetting,
        meta: { title: '时间设置' }
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: '肉联大屏数据管理系统' }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
    meta: { title: '肉联大屏数据管理系统' }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
