<template>
  <div>
    <pageTitle :title="pageTitle"></pageTitle>
    <div class="head bb p30 flex fw_w">
      <div>
        <div>账号名</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="username"
                    placeholder="请输入账号名"></el-input>
        </div>
      </div>
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div>
    </div>

    <div class="bb pl30 pr30">
      <div class="mb20">
        <el-button type="primary"
                   @click="modifyFormShow = true">新增管理员</el-button>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="65vh"
                    style="width: 100%">
            <el-table-column label="ID"
                             width="100"
                             prop="muserId">
            </el-table-column>
            <el-table-column label="用户名"
                             width="180"
                             prop="username">
            </el-table-column>
            <el-table-column label="看板数量"
                             width="120">
              <template #default="scope">
                {{scope.row.bsidArr.length}}
              </template>
            </el-table-column>
            <el-table-column label="添加时间"
                             width="120"
                             prop="addTimeText">
            </el-table-column>
            <el-table-column label="登录时间"
                             width="120"
                             prop="loginTimeText">
            </el-table-column>
            <el-table-column label="操作"
                             width="140"
                             align="center"
                             fixed="right">
              <template #default="scope">
                <el-button type="primary"
                           size="small"
                           :disabled="scope.row.username=='admin'"
                           @click="handleModify(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="small"
                           :disabled="scope.row.username=='admin'"
                           @click="handleDel(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog width="680px"
               v-model="modifyFormShow"
               :title="modifyId?'修改管理员':'新增管理员'"
               :before-close="clearForm">
      <el-form :model="editForm"
               ref="modifyForm">
        <div>
          <el-form-item size="large"
                        label="账号名"
                        :label-width="formLabelWidth1">
            <el-input :disabled="modifyId"
                      v-model="modifyForm.username"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="密码"
                        :label-width="formLabelWidth1">
            <el-input :placeholder="modifyId?'不填则默认不修改':''"
                      v-model="modifyForm.password"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="看板"
                        :label-width="formLabelWidth1">
            <el-select v-model="modifyForm.bs_ids"
                       filterable
                       multiple
                       placeholder="请选择看板"
                       style="width: 240px">
              <el-option v-for="item in bsList"
                         :key="item.bsId.toString()"
                         :label="item.name"
                         :value="item.bsId.toString()" />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm">取消</el-button>
          <el-button type="primary"
                     @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/common";
import pageTitle from "@/common/pageTitle.vue";
import { formatTime } from "@/utils/common";
const CryptoJS = require("crypto-js");
function md5Encrypt(data) {
  return CryptoJS.MD5(data).toString();
}
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      pageTitle: "管理员列表",
      username: "", // 关键词
      loading: false,
      bsList: [],
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      modifyFormShow: false,
      modifyId: null,
      modifyForm: {
        username: "",
        password: "",
        bs_ids: [],
      },
      formLabelWidth1: "150px",
    };
  },
  created() {
console.warn('1')
  },
  mounted() {
    this.getBSList();
    this.getManagerList(this.page);
  },
  methods: {
    retrieval() {
      this.page = 1;
      this.tableData = [];
      this.getManagerList(this.page);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getManagerList(val);
    },
    async getBSList() {
      const res = await Service.getBSList({
        access_token: localStorage.getItem("access_token"),
        page: 1,
        pageSize: 200,
      });
      this.bsList = res.data;
    },
    async getManagerList(page) {
      this.loading = true;
      const res = await Service.getManagerList({
        access_token: localStorage.getItem("access_token"),
        username: this.username,
        page,
        pageSize: this.pageSize,
      });
      var tableData = res.data;
      for (const item of tableData) {
        item.addTimeText = formatTime(item.addTime / 1000, "Y-M-D");
        item.loginTimeText = formatTime(item.loginTime / 1000, "Y-M-D");
        item.bsidArr = item.bs_ids ? item.bs_ids.split(",") : [];
      }
      this.tableData = tableData;
      this.total = res.count;
      this.loading = false;
    },
    async copyText(e) {
      await navigator.clipboard.writeText(e);
      this.$message.success("已复制到剪贴板");
    },
    handleModify(index, row) {
      this.modifyId = row.muserId;
      this.modifyForm.username = row.username;
      this.modifyForm.password = "";
      this.modifyForm.bs_ids = row.bs_ids.split(",");
      this.modifyFormShow = true;
    },
    handleDel(index, row) {
      this.$confirm("确认删除该条记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelApi(row.muserId);
        })
        .catch(() => {});
    },
    async handleDelApi(id) {
      const res = await Service.delManager({
        access_token: localStorage.getItem("access_token"),
        muserId: id,
      });
      this.$message.success(res.resultMsg);
      this.getManagerList(this.page);
    },
    clearForm() {
      this.modifyId = null;
      this.modifyForm.username = "";
      this.modifyForm.password = "";
      this.modifyForm.bs_ids = "";
      this.modifyFormShow = false;
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      var modifyId = this.modifyId;
      console.log("modifyForm", modifyForm);
      if (!modifyForm.username) {
        this.$message.error("账号名不可为空");
        return false;
      }
      if (!modifyForm.password && !modifyId) {
        this.$message.error("密码不可为空");
        return false;
      }
      if (!modifyForm.bs_ids.length) {
        this.$message.error("请至少选择一个看板");
        return false;
      }
      let res = null;
      if (!modifyId) {
        res = await Service.addManager({
          access_token: localStorage.getItem("access_token"),
          username: modifyForm.username,
          password: md5Encrypt(modifyForm.password),
          bs_ids: modifyForm.bs_ids.toString(),
        });
      } else {
        res = await Service.editManager({
          access_token: localStorage.getItem("access_token"),
          muserId: modifyId,
          username: modifyForm.username,
          password: modifyForm.password,
          bs_ids: modifyForm.bs_ids.toString(),
        });
      }

      this.$message.success(res.resultMsg);
      this.modifyFormShow = false;
      this.clearForm();
      this.getManagerList(this.page);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>