<template>
  <div>
    <pageTitle :title="pageTitle"></pageTitle>
    <div class="head bb p30 flex fw_w">

    </div>

    <div class="bb pl30 pr30">
      <div class="mb20">
        <el-button type="primary"
                   @click="modifyFormShow = true">新增榜单</el-button>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="65vh"
                    style="width: 100%">
            <el-table-column label="ID"
                             width="100"
                             prop="id">
            </el-table-column>
            <el-table-column label="大屏看板ID号"
                             width="120"
                             prop="bs_id">
            </el-table-column>
            <el-table-column label="日期"
                             width="180">
              <template #default="scope">
                {{scope.row.modifDateText}}
              </template>
            </el-table-column>
            <el-table-column label="交易榜单"
                             width="250">
              <template #default="scope">
                <div v-for="(item,index) in scope.row.tradeList"
                     :key="index">{{item}}</div>
              </template>
            </el-table-column>
            <el-table-column label="订单榜单"
                             width="250">
              <template #default="scope">
                <div v-for="(item,index) in scope.row.orderList"
                     :key="index">{{item}}</div>
              </template>
            </el-table-column>
            <el-table-column label="商品榜单"
                             width="250">
              <template #default="scope">
                <div v-for="(item,index) in scope.row.goodsList"
                     :key="index">{{item}}</div>
              </template>
            </el-table-column>
            <el-table-column label="店长榜单"
                             width="250">
              <template #default="scope">
                <div v-for="(item,index) in scope.row.storeList"
                     :key="index">{{item}}</div>
              </template>
            </el-table-column>
            <el-table-column label="团长榜单"
                             width="250">
              <template #default="scope">
                <div v-for="(item,index) in scope.row.teamList"
                     :key="index">{{item}}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="140"
                             align="center"
                             fixed="right">
              <template #default="scope">
                <el-button type="primary"
                           size="small"
                           @click="handleModify(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="small"
                           @click="handleDel(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog width="680px"
               v-model="modifyFormShow"
               :title="modifyId?'修改榜单':'新增榜单'"
               :before-close="clearForm">
      <el-form :model="editForm"
               ref="modifyForm">
        <div>
          <el-form-item size="large"
                        label="日期"
                        :label-width="formLabelWidth1">
            <el-date-picker v-model="modifyForm.modifDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="x">
            </el-date-picker>
          </el-form-item>
          <el-form-item size="large"
                        label="交易1"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.tradeList[0]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="交易2"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.tradeList[1]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="交易3"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.tradeList[2]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="交易4"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.tradeList[3]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="交易5"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.tradeList[4]"
                      autocomplete="off" />
          </el-form-item>

          <el-form-item size="large"
                        label="订单1"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.orderList[0]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="订单2"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.orderList[1]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="订单3"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.orderList[2]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="订单4"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.orderList[3]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="订单5"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.orderList[4]"
                      autocomplete="off" />
          </el-form-item>

          <el-form-item size="large"
                        label="商品1"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.goodsList[0]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="商品2"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.goodsList[1]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="商品3"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.goodsList[2]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="商品4"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.goodsList[3]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="商品5"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.goodsList[4]"
                      autocomplete="off" />
          </el-form-item>

          <el-form-item size="large"
                        label="店长1"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.storeList[0]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="店长2"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.storeList[1]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="店长3"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.storeList[2]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="店长4"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.storeList[3]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="店长5"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.storeList[4]"
                      autocomplete="off" />
          </el-form-item>

          <el-form-item size="large"
                        label="团长1"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.teamList[0]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="团长2"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.teamList[1]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="团长3"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.teamList[2]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="团长4"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.teamList[3]"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="团长5"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.teamList[4]"
                      autocomplete="off" />
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm">取消</el-button>
          <el-button type="primary"
                     @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/common";
import pageTitle from "@/common/pageTitle.vue";
import { formatTime } from "@/utils/common";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      pageTitle: "排行榜",
      title: "", // 关键词
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      modifyFormShow: false,
      modifyId: null,
      modifyForm: {
        tradeList: ["交易1", "交易2", "交易3", "交易4", "交易5"],
        orderList: ["订单1", "订单2", "订单3", "订单4", "订单5"],
        goodsList: ["商品1", "商品2", "商品3", "商品4", "商品5"],
        storeList: ["店长1", "店长2", "店长3", "店长4", "店长5"],
        teamList: ["团长1", "团长2", "团长3", "团长4", "团长5"],
        modifDate: "",
      },
      formLabelWidth1: "150px",
    };
  },
  mounted() {
    this.getRankList(this.page);
  },
  methods: {
    retrieval() {
      this.page = 1;
      this.tableData = [];
      this.getRankList(this.page);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getRankList(val);
    },
    // 筛选榜单列表
    async getRankList(page) {
      this.loading = true;
      const res = await Service.getRankList({
        bs_id: localStorage.getItem("bs_id"),
        page,
        pageSize: this.pageSize,
      });
      var tableData = res.data;
      for (const item of tableData) {
        item.modifDateText = formatTime(item.modifDate / 1000, "Y-M-D");
      }
      this.tableData = tableData;
      this.total = res.count;
      this.loading = false;
    },
    handleModify(index, row) {
      this.modifyId = row.id;
      this.modifyForm.tradeList = row.tradeList;
      this.modifyForm.orderList = row.orderList;
      this.modifyForm.goodsList = row.goodsList;
      this.modifyForm.storeList = row.storeList;
      this.modifyForm.teamList = row.teamList;
      this.modifyForm.modifDate = row.modifDate;
      this.modifyFormShow = true;
    },
    handleDel(index, row) {
      this.$confirm("确认删除该条记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelApi(row.id);
        })
        .catch(() => {});
    },
    async handleDelApi(id) {
      const res = await Service.delRank({
        access_token: localStorage.getItem("access_token"),
        id,
      });
      this.$message.success(res.resultMsg);
      this.getRankList(this.page);
    },
    clearForm() {
      this.modifyId = null;
      this.modifyForm.tradeList = ["", "", "", "", ""];
      this.modifyForm.orderList = ["", "", "", "", ""];
      this.modifyForm.goodsList = ["", "", "", "", ""];
      this.modifyForm.storeList = ["", "", "", "", ""];
      this.modifyForm.teamList = ["", "", "", "", ""];
      this.modifyForm.modifDate = "";
      this.modifyFormShow = false;
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      var modifyId = this.modifyId;
      var num1 = 0
      var num2 = 0
      var num3 = 0
      var num4 = 0
      var num5 = 0
      for (const item of modifyForm.tradeList) {
        if(!item) {
          num1++
        }
      }
      for (const item of modifyForm.orderList) {
        if(!item) {
          num2++
        }
      }
      for (const item of modifyForm.goodsList) {
        if(!item) {
          num3++
        }
      }
      for (const item of modifyForm.storeList) {
        if(!item) {
          num4++
        }
      }
      for (const item of modifyForm.teamList) {
        if(!item) {
          num5++
        }
      }
      
      if (!modifyForm.modifDate) {
        this.$message.error("日期不可为空");
        return false;
      }
      if (num1) {
        this.$message.error("交易数据不可为空");
        return false;
      }
      if (num2) {
        this.$message.error("订单数据不可为空");
        return false;
      }
      if (num3) {
        this.$message.error("商品数据不可为空");
        return false;
      }
      if (num4) {
        this.$message.error("店长数据不可为空");
        return false;
      }
      if (num5) {
        this.$message.error("团长数据不可为空");
        return false;
      }
      let res = null;
      if (!modifyId) {
        res = await Service.addRank({
          access_token: localStorage.getItem("access_token"),
          bs_id: localStorage.getItem("bs_id"),
          tradeList: modifyForm.tradeList,
          orderList: modifyForm.orderList,
          goodsList: modifyForm.goodsList,
          storeList: modifyForm.storeList,
          teamList: modifyForm.teamList,
          modifDate: modifyForm.modifDate,
        });
      } else {
        res = await Service.editRank({
          access_token: localStorage.getItem("access_token"),
          id: modifyId,
          bs_id: localStorage.getItem("bs_id"),
          tradeList: modifyForm.tradeList,
          orderList: modifyForm.orderList,
          goodsList: modifyForm.goodsList,
          storeList: modifyForm.storeList,
          teamList: modifyForm.teamList,
          modifDate: modifyForm.modifDate,
        });
      }

      this.$message.success(res.resultMsg);
      this.modifyFormShow = false;
      this.clearForm();
      this.getRankList(this.page);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>