<template>
  <div>
    <pageTitle :title="pageTitle"></pageTitle>
    <div class="head bb p30 flex fw_w">
      <!-- <div>
        <div>分类标题</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="title"
                    placeholder="请输入分类标题"></el-input>
        </div>
      </div>
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div> -->
    </div>

    <div class="bb pl30 pr30">
      <div class="mb20">
        <el-button type="primary"
                   @click="modifyFormShow = true">新增分类</el-button>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="65vh"
                    style="width: 100%">
            <el-table-column label="ID"
                             width="100"
                             prop="typeId">
            </el-table-column>
            <el-table-column label="大屏看板ID号"
                             width="120"
                             prop="bs_id">
            </el-table-column>
            <el-table-column label="头条类型名称"
                             width="250"
                             prop="name">
            </el-table-column>
            <el-table-column label="类型左右展示序号"
                             width="250"
                             prop="sortNo">
            </el-table-column>
            <el-table-column label="操作"
                             width="140"
                             align="center">
              <template #default="scope">
                <el-button type="primary"
                           size="small"
                           @click="handleModify(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="small"
                           @click="handleDel(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="15"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog width="680px"
               v-model="modifyFormShow"
               :title="modifyId?'修改分类':'新增分类'"
               :before-close="clearForm">
      <el-form :model="editForm"
               ref="modifyForm">
        <div>
          <el-form-item size="large"
                        label="分类标题"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.name"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item size="large"
                        label="类型左右展示序号"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.sortNo"
                      autocomplete="off" />
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm">取消</el-button>
          <el-button type="primary"
                     @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/common";
import pageTitle from "@/common/pageTitle.vue";
import { formatTime } from "@/utils/common";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      pageTitle: "分类管理列表",
      title: "", // 关键词
      loading: false,
      tableData: [],
      page: 1,
      total: 0,
      modifyFormShow: false,
      modifyId: null,
      modifyForm: {
        name: "",
        sortNo: "",
      },
      formLabelWidth1: "150px",
    };
  },
  mounted() {
    this.getAllType(this.page);
  },
  methods: {
    retrieval() {
      this.page = 1;
      this.tableData = [];
      this.getAllType(this.page);
    },
    handleCurrentChange(val) {
      this.getAllType(val);
    },
    // 筛选分类列表
    async getAllType(page) {
      this.loading = true;
      const res = await Service.getAllType({
        bs_id: localStorage.getItem("bs_id"),
        title: this.title,
        page,
      });
      var tableData = res.data;
      for (const item of tableData) {
        item.modifTimeText = formatTime(item.modifTime / 1000, "Y-M-D h:m:s");
      }
      this.tableData = tableData;
      this.total = res.count;
      this.loading = false;
    },
    handleModify(index, row) {
      this.modifyId = row.typeId;
      this.modifyForm.name = row.name;
      this.modifyForm.sortNo = row.sortNo;
      this.modifyFormShow = true;
    },
    handleDel(index, row) {
      this.$confirm("确认删除该条记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelApi(row.typeId);
        })
        .catch(() => {});
    },
    async handleDelApi(typeId) {
      const res = await Service.delType({
        access_token: localStorage.getItem("access_token"),
        typeId,
      });
      this.$message.success(res.resultMsg);
      this.getAllType(this.page);
    },
    clearForm() {
      this.modifyId = null;
      this.modifyForm.name = "";
      this.modifyForm.sortNo = "";
      this.modifyFormShow = false;
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      var modifyId = this.modifyId;
      if (!modifyForm.name) {
        this.$message.error("分类标题不可为空");
        return false;
      }
      if (!modifyForm.sortNo) {
        this.$message.error("类型左右展示序号不可为空");
        return false;
      }
      let res = null;
      if (!modifyId) {
        res = await Service.addType({
          access_token: localStorage.getItem("access_token"),
          bs_id: localStorage.getItem("bs_id"),
          name: modifyForm.name,
          sortNo: modifyForm.sortNo,
        });
      } else {
        res = await Service.editType({
          access_token: localStorage.getItem("access_token"),
          typeId: modifyId,
          name: modifyForm.name,
          sortNo: modifyForm.sortNo,
        });
      }

      this.$message.success(res.resultMsg);
      this.modifyFormShow = false;
      this.clearForm();
      this.getAllType(this.page);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>