<template>
  <div>
    <pageTitle :title="pageTitle"></pageTitle>
    <div class="head bb p30 flex fw_w">

    </div>

    <div class="bb pl30 pr30">
      <el-form :model="editForm"
               ref="modifyForm">
        <div>
          <el-form-item size="large"
                        label="数据展示日期"
                        :label-width="formLabelWidth1">
            <el-date-picker v-model="modifyForm.bsTime"
                            type="date"
                            placeholder="选择日期"
                            value-format="x">
            </el-date-picker>
          </el-form-item>
          <el-form-item size="large"
                        label="折线图展示天数"
                        :label-width="formLabelWidth1">
            <el-input v-model="modifyForm.lineDays"
                      style="width: 240px;"
                      autocomplete="off" />
          </el-form-item>
          <el-form-item :label-width="formLabelWidth1">
            <el-button type="primary" @click="submitForm">确定</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/common";
import pageTitle from "@/common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      pageTitle: "时间设置",
      type: 1,
      title: "", // 关键词
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      modifyId: null,
      modifyForm: {
        bsTime: "",
        lineDays: "",
      },
      formLabelWidth1: "150px",
    };
  },
  mounted() {
    this.getBSendTime();
  },
  methods: {
    retrieval() {
      this.page = 1;
      this.tableData = [];
      this.getBSendTime(this.page);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getBSendTime(val);
    },
    async getBSendTime(page) {
      const res = await Service.getBSendTime({
        bs_id: localStorage.getItem("bs_id"),
      });
      this.modifyForm = res.data;
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      if (!modifyForm.bsTime) {
        this.$message.error("数据展示日期不可为空");
        return false;
      }
      if (!modifyForm.lineDays) {
        this.$message.error("折线图展示天数不可为空");
        return false;
      }
      const res = await Service.setBSendTime({
        access_token: localStorage.getItem("access_token"),
        bs_id: localStorage.getItem("bs_id"),
        bsTime: modifyForm.bsTime,
        lineDays: modifyForm.lineDays,
      });

      this.$message.success(res.resultMsg);
      this.getBSendTime(this.page);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>